import React from 'react';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { CalendarRange } from "lucide-react";
import useGoogleSheetData from "@/components/utility/useGoogleSheet";

const KeyDatesContent = ({ data, loading, error }) => {
  const keyDatesSheet = data.find(sheet => sheet.id === 'Key Dates');
  const sheetData = keyDatesSheet ? keyDatesSheet.data : [];

  return (
    <>
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error.message}</div>}
      {!loading && !error && sheetData.length > 0 ? (
        sheetData.map((item, index) => (
          <div key={index} className="py-3">
            <p className="text-xl pt-2 mb-2 font-bold">{item.title}</p>
            <p className="text-base">{item.date}</p>
          </div>
        ))
      ) : (
        !loading && !error && <div>No data available</div>
      )}
    </>
  );
};

export function KeyDatesModal() {
  const { data, loading, error } = useGoogleSheetData(
    '14TmCtf36src1Xy0260eJBR5wVsa1cciGXzVBqB1Jqc4',
    'Key Dates'
  );

  return (
    <Sheet>
      <SheetTrigger className="fixed bottom-5 left-5 rounded-lg py-3 px-6 text-xl font-bold drop-shadow-lg hover:drop-shadow-xl flex items-center gap-2 uppercase bg-primary text-white z-50">
        <CalendarRange /> Key Dates
      </SheetTrigger>
      <SheetContent side={"left"}>
        <SheetHeader>
          <SheetTitle className="mb-0 pb-0 text-2xl font-bold">Key Dates</SheetTitle>
          <SheetDescription>
            <KeyDatesContent data={data} loading={loading} error={error} />
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
}

export function KeyDates() {
  const { data, loading, error } = useGoogleSheetData(
    '14TmCtf36src1Xy0260eJBR5wVsa1cciGXzVBqB1Jqc4',
    'Key Dates'
  );

  return <KeyDatesContent data={data} loading={loading} error={error} />;
}
