import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "@reach/router";
import { getPageData } from '@/data/pageData';
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import { graphql, useStaticQuery, Link } from "gatsby";
import FWNavBar from "@/components/navbar/FullWidthNavBar";
import InLineNavBar from "@/components/navbar/InLineNavBar";
import config from '@/utils/config';
import Autoplay from "embla-carousel-autoplay";
import CountdownTimer from "@/components/utility/Countdown";

const Header = () => {
  const location = useLocation();
  const { heading } = getPageData(location.pathname);

  const [currentHeading, setCurrentHeading] = useState(heading);

  const carouselItems = [
    { src: "/images/nz1.webp", alt: "New Zealand Mountain 1" },
    { src: "/images/nz2.webp", alt: "New Zealand Mountain 2" },
    { src: "/images/nz3.webp", alt: "New Zealand Mountain 3" },
    { src: "/images/nz4.webp", alt: "New Zealand Mountain 4" },
    { src: "/images/nz5.webp", alt: "New Zealand Mountain 5" },
    { src: "/images/nz6.webp", alt: "New Zealand Mountain 6" },
    { src: "/images/nz7.webp", alt: "New Zealand Mountain 7" },
    { src: "/images/nz8.webp", alt: "New Zealand Mountain 8" },
    { src: "/images/nz9.webp", alt: "New Zealand Mountain 9" },
    { src: "/images/nz10.webp", alt: "New Zealand Mountain 10" },
  ];

  const [carouselOrder, setCarouselOrder] = useState(carouselItems);

  const shuffleArray = (array) => {
    let shuffled = array.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  useEffect(() => {
    if (location.pathname !== "/") {
      setCarouselOrder(shuffleArray(carouselItems));
    } else {
      setCarouselOrder([carouselItems[0], carouselItems[4]]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (heading !== currentHeading) {
      setCurrentHeading(heading);
    }
  }, [heading, currentHeading]);

  const memoizedAnnouncement = useMemo(() => (
    <div className="text-white text-base lg:text-2xl leading-normal lg:leading-10 drop-shadow-lg">
      We are delighted to announce the 26th AAABG Conference, which will be held from <b>Tuesday, June 24th to Thursday, June 26th, 2025</b>. This prestigious event will take place at the beautiful <b>Millennium Hotel</b> in <b>Tāhuna Queenstown, New Zealand</b>.
    </div>
  ), []);

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "AAABG2025-logo-rev.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <>
      <header> 
        {config.inlineNavbar ? <InLineNavBar /> : <FWNavBar />}
        <div className={`relative flex items-center justify-center ${location.pathname === "/" ? "min-h-screen" : "h-full min-h-[360px] lg:min-h-[500px]"}`}>
          
          <div className="absolute h-full">
            <div className="absolute w-full h-full z-10 bg-black/30 lg:bg-black/10"/>
            
            <div className={`relative w-full h-full z-0 overflow-hidden ${location.pathname === "/" ? "min-h-screen" : "h-full min-h-[360px] lg:min-h-[500px]"}`}>
              <Carousel
                plugins={[
                  Autoplay({
                    delay: 5000,
                  }),
                ]}
                opts={{
                  align: "center",
                  loop: true,
                }}
              >
                <CarouselContent>
                  {carouselOrder.map((item, index) => (
                    <CarouselItem key={index}>
                      <img
                        src={item.src}
                        alt={item.alt}
                        className={`w-full ${location.pathname === "/" ? "min-h-screen" : "h-[360px] lg:h-[500px]"} object-cover`}
                      />
                    </CarouselItem>
                  ))}
                </CarouselContent>
              </Carousel>
            </div>
          </div>
          
          <div className="relative container w-full mx-auto z-20">
            <div className={`grid grid-cols-1 items-center gap-6 lg:gap-12 py-10 ${location.pathname === "/" ? "lg:grid-cols-2" : "lg:grid-cols-1"}`}>
              {location.pathname === "/" ? (
                <>
                  <div className="space-y-6 bg-black/30 backdrop-blur-sm p-6 lg:p-8 rounded-lg">
                    <Link to="/"><img src={data.logo.publicURL} alt="AAABG 2025 logo" className="w-auto h-auto drop-shadow-lg" /></Link>
                    <div className="text-c4 flex flex-row items-end text-lg lg:text-2xl border-t border-white/50 pt-3 text-nowrap">
                      Conference starts in <CountdownTimer targetDate="2025-06-24" />
                    </div>
                  </div>
                  <div className="drop-shadow-md">
                    <h1 className="text-2xl font-bold leading-tight text-c4 md:text-3xl mb-6">
                      {currentHeading}
                    </h1>
                    {memoizedAnnouncement}
                  </div>
                </>
              ) : (
                <div className="grid grid-cols-1 lg:grid-cols-2 items-end text-end">
                  <Link to="/"><img src={data.logo.publicURL} alt="AAABG 2025 logo" className="h-[250px] drop-shadow-lg" /></Link>
                  <div>
                    <h1 className="text-2xl font-black leading-tight text-white sm:text-2xl md:text-3xl drop-shadow">
                      {currentHeading}
                    </h1>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
