exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accommodation-js": () => import("./../../../src/pages/accommodation.js" /* webpackChunkName: "component---src-pages-accommodation-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-call-for-papers-js": () => import("./../../../src/pages/call-for-papers.js" /* webpackChunkName: "component---src-pages-call-for-papers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-explore-aotearoa-js": () => import("./../../../src/pages/explore-aotearoa.js" /* webpackChunkName: "component---src-pages-explore-aotearoa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-news-js": () => import("./../../../src/pages/latest-news.js" /* webpackChunkName: "component---src-pages-latest-news-js" */),
  "component---src-pages-our-sponsors-js": () => import("./../../../src/pages/our-sponsors.js" /* webpackChunkName: "component---src-pages-our-sponsors-js" */),
  "component---src-pages-preliminary-program-js": () => import("./../../../src/pages/preliminary-program.js" /* webpackChunkName: "component---src-pages-preliminary-program-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-queenstown-js": () => import("./../../../src/pages/queenstown.js" /* webpackChunkName: "component---src-pages-queenstown-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-sponsorship-and-exhibition-opportunities-js": () => import("./../../../src/pages/sponsorship-and-exhibition-opportunities.js" /* webpackChunkName: "component---src-pages-sponsorship-and-exhibition-opportunities-js" */),
  "component---src-pages-tiaki-promise-js": () => import("./../../../src/pages/tiaki-promise.js" /* webpackChunkName: "component---src-pages-tiaki-promise-js" */),
  "component---src-pages-venue-js": () => import("./../../../src/pages/venue.js" /* webpackChunkName: "component---src-pages-venue-js" */),
  "component---src-pages-visa-information-js": () => import("./../../../src/pages/visa-information.js" /* webpackChunkName: "component---src-pages-visa-information-js" */)
}

