import React, { useState } from 'react';
import { Link } from "gatsby";
import { DropdownMenuTrigger, DropdownMenuItem, DropdownMenuContent, DropdownMenu } from "@/components/ui/dropdown-menu";
import { ChevronDownIcon, ChevronUpIcon } from "@/components/icons/icons";
import NavItem from "@/components/navbar/NavItem";
import { menuItems } from "@/data/menuItems";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Menu } from "lucide-react";
import LangSelector from "../translation/LangSelector";
import config from '@/utils/config';

export default function NavBar() {
  const [openDropdownDesktop, setOpenDropdownDesktop] = useState(null);
  const [openDropdownMobile, setOpenDropdownMobile] = useState(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  // Toggle function for desktop menu
  const handleToggleDropdownDesktop = (index) => {
    setOpenDropdownDesktop(openDropdownDesktop === index ? null : index);
  };

  // Toggle function for mobile menu
  const handleToggleDropdownMobile = (index) => {
    setOpenDropdownMobile(openDropdownMobile === index ? null : index);
  };

  const handleCloseDropdown = () => {
    setOpenDropdownDesktop(null);
    setOpenDropdownMobile(null);
  };

  const toggleSheet = () => {
    setIsSheetOpen(!isSheetOpen);
  };

  return (
    <section className="relative z-30">
      <div className="relative container mx-auto flex items-center justify-between py-4 px-6">
        
        {/* Logo */}
        <Link className="flex items-center" to="/">
          <span className="font-bold text-3xl">Event Logo</span>
        </Link>

        {/* Desktop Navbar */}
        <nav className="hidden md:flex space-x-4 relative">
          {menuItems.header.map((item, index) => (
            item.dropdown ? (
              <DropdownMenu key={index}>
                <DropdownMenuTrigger
                  className="flex items-center hover:text-blue-500 cursor-pointer"
                  onClick={() => handleToggleDropdownDesktop(index)}
                >
                  {item.label}
                  {openDropdownDesktop === index ? (
                    <ChevronUpIcon className="h-4 w-4 ml-1" />
                  ) : (
                    <ChevronDownIcon className="h-4 w-4 ml-1" />
                  )}
                </DropdownMenuTrigger>
                {openDropdownDesktop === index && (
                  <DropdownMenuContent className="absolute mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none lg:right-[-50px]">
                    {item.items.map((subItem, subIndex) => (
                      <DropdownMenuItem key={subIndex} className="hover:bg-slate-100" onClick={handleCloseDropdown}>
                        <Link to={subItem.to} className="w-full">{subItem.label}</Link>
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                )}
              </DropdownMenu>
            ) : (
              <NavItem key={index} to={item.to}>{item.label}</NavItem>
            )
          ))}
          {config.languageSelector && <LangSelector />}
        </nav>

        {/* Mobile Navbar */}
        <nav className="xl:hidden flex items-center bg-primary p-3 fixed top-5 right-5">
          <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
            <SheetTrigger asChild>
              <button onClick={toggleSheet} aria-label="Open Menu">
                <Menu className="h-6 w-6" />
              </button>
            </SheetTrigger>
            <SheetContent side="right" className="w-10/12">
              <SheetHeader className="flex flex-row items-center gap-5" id="mobile-nav-header">
                <SheetTitle className="text-primary font-black text-2xl mb-0 mt-0">Menu</SheetTitle>
                {config.languageSelector && <LangSelector />}
              </SheetHeader>
              <div className="mt-4">
                {menuItems.header.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="my-2">
                      {item.dropdown ? (
                        <>
                          <button
                            className="w-full py-2 text-left flex items-center justify-between font-bold text-gray-600"
                            onClick={() => handleToggleDropdownMobile(index)}
                          >
                            {item.label}
                            {openDropdownMobile === index ? (
                              <ChevronUpIcon className="h-4 w-4" />
                            ) : (
                              <ChevronDownIcon className="h-4 w-4" />
                            )}
                          </button>
                          {openDropdownMobile === index && (
                            <div className="mt-2 pl-4">
                              {item.items.map((subItem, subIndex) => (
                                <Link
                                  key={subIndex}
                                  to={subItem.to}
                                  className="flex py-1 text-gray-600"
                                  onClick={() => setIsSheetOpen(false)}
                                >
                                  {subItem.label}
                                </Link>
                              ))}
                            </div>
                          )}
                        </>
                      ) : (
                        <Link
                          to={item.to}
                          className="block py-2 text-gray-600 font-bold"
                          onClick={() => setIsSheetOpen(false)}
                        >
                          {item.label}
                        </Link>
                      )}
                    </div>
                    <hr/>
                  </React.Fragment>
                ))}
              </div>
            </SheetContent>
          </Sheet>
        </nav>

      </div>
    </section>
  );
}
