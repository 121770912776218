export const menuItems = {

  // header menu items
  header: [
    {
      label: "Home",
      to: "",
      dropdown: true,
      items: [
        { label: "Home", to: "/" },
        { label: "About", to: "/about" },
      ],
    },
    {
      label: "Program",
      to: "",
      dropdown: true,
      items: [
        { label: "Preliminary Program", to: "/preliminary-program" },
        { label: "Call for Papers", to: "/call-for-papers" },
        { label: "Awards", to: "/awards" },
      ],
    },
    {
      label: "Registration",
      to: "",
      dropdown: true,
      items: [
        { label: "Registration", to: "/registration" },
        { label: "Privacy Policy", to: "/privacy-policy" },
      ],
    },
    {
      label: "Accommodation",
      to: "",
      dropdown: true,
      items: [
        { label: "Accommodation", to: "/accommodation" },
      ],
    },
    {
      label: "Sponsorship and Exhibition",
      to: "",
      dropdown: true,
      items: [
        { label: "Sponsorship and Exhibition Opportunities", to: "/sponsorship-and-exhibition-opportunities" },
        { label: "Our Sponsors", to: "/our-sponsors" },
      ],
    },
    {
      label: "Destination",
      to: "",
      dropdown: true,
      items: [
        { label: "Venue", to: "/venue" },
        { label: "Queenstown", to: "/queenstown" },
        { label: "Explore Aotearoa", to: "/explore-aotearoa" },
        { label: "Tiaki Promise", to: "/tiaki-promise" },
        { label: "Visa Information", to: "/visa-information" },
      ],
    },
    {
      label: "Contact Us",
      to: "/contact",
      dropdown: false,
    },
  ],

  // footer menu items
  footer: [
    {
      label: "Home",
      items: [
        { label: "Home", to: "/" },
        { label: "About", to: "/about" },
      ],
    },
    {
      label: "Program",
      items: [
        { label: "Preliminary Program", to: "/preliminary-program" },
        { label: "Call for Papers", to: "/call-for-papers" },
        { label: "Awards", to: "/awards" },
      ],
    },
    {
      label: "Registration",
      items: [
        { label: "Registration", to: "/registration" },
        { label: "Privacy Policy", to: "/privacy-policy" },
        { label: "Accommodation", to: "/accommodation" },
      ],
    },
    {
      label: "Sponsorship and Exhibition",
      items: [
        { label: "Sponsorship and Exhibition Opportunities", to: "/sponsorship-and-exhibition-opportunities" },
      ],
    },
    {
      label: "Destination",
      items: [
        { label: "Venue", to: "/venue" },
        { label: "Queenstown", to: "/queenstown" },
        { label: "Explore Aotearoa", to: "/explore-aotearoa" },
        { label: "Tiaki Promise", to: "/tiaki-promise" },
        { label: "Visa Information", to: "/visa-information" },
      ],
    },
  ],

  // social menu items
  social: [
    {
      name: "facebook",
      url: "#",
      screenReader: "Follow us on Facebook",
    },
    {
      name: "linkedin",
      url: "#",
      screenReader: "Follow us on LinkedIn",
    },
    {
      name: "instagram",
      url: "#",
      screenReader: "Follow us on Instagram",
    },
    {
      name: "twitter",
      url: "#",
      screenReader: "Follow us on Twitter",
    },
  ]
};
