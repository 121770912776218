import React, { useEffect, useState } from "react";
import { useLocation } from '@reach/router';
import UserForm from '@/components/form/EOI-Form';
import { KeyDates } from "@/components/KeyDates";
import { StaticImage } from "gatsby-plugin-image";
import config from '@/utils/config';
import { Link } from "gatsby";

const animalLineArt = [
  { src: "/images/animal1.png", alt: "Animal Line Art 1" },
  { src: "/images/animal2.png", alt: "Animal Line Art 2" },
  { src: "/images/animal3.png", alt: "Animal Line Art 3" },
  { src: "/images/animal4.png", alt: "Animal Line Art 4" },
  { src: "/images/animal5.png", alt: "Animal Line Art 5" },
  { src: "/images/animal6.png", alt: "Animal Line Art 6" },
  { src: "/images/animal7.png", alt: "Animal Line Art 7" },
  { src: "/images/animal8.png", alt: "Animal Line Art 8" },
  { src: "/images/animal9.png", alt: "Animal Line Art 9" },
];

export default function Footer() {
  const eventName = 'AAABG 2025';
  const location = useLocation();

  const [randomImage, setRandomImage] = useState(null);

  const customPages = config.sidebar.customPages || [];

  const hideSection = config.sidebar.enabled && customPages.includes(location.pathname);

  useEffect(() => {

    const randomIndex = Math.floor(Math.random() * animalLineArt.length);
    setRandomImage(animalLineArt[randomIndex]);
  }, [location.pathname]);

  if (!randomImage) {
    return null;
  }

  return (
    <footer>
      {!hideSection && (
        <section className="bg-c3">
          <div className="container px-4 md:px-6 py-12 md:py-16">
            <h2 className="text-center mt-0 mb-10 text-c1">Key Dates</h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <KeyDates />
            </div>
          </div>
        </section>
      )}

      <section className="bg-c1">
        <div className="container px-4 md:px-6 pt-12 lg:pt-24 py-24 relative z-10">
          <div className="flex items-center justify-center w-full">
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-center w-full rounded-lg">
              <div className="pb-10 lg:pb-0 lg:pr-8 border-b lg:border-b-0 border-r-0 lg:border-r border-gray-300 dark-theme">
                <h2 className="mb-4 text-white">Express your interest today!</h2>
                <UserForm eventName={eventName} />
              </div>
              <div className="lg:pl-8 pt-8 lg:pt-0">
                <div>
                  <Link to="/">
                    <StaticImage
                      src="../images/AAABG2025-logo-rev.svg"
                      alt="AAABG Logo"
                      width={550}
                      loading="lazy"
                      placeholder="none"
                      formats={["auto", "webp", "avif"]}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src={randomImage.src} alt={randomImage.alt} className="absolute bottom-0 left-10 invert opacity-20 w-full max-w-96 z-0" />
        <StaticImage
          src="../images/AAABG2025-animals-white.png"
          width={300}
          alt="animals logo"
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
          className="!absolute bottom-0 right-10 z-0"
        />
      </section>

      <section className="bg-white">
        <div className="container mx-auto px-4 md:px-6 text-center">
          <div className="flex flex-col lg:flex-row justify-center gap-3 py-6">
            <div className="order-2 lg:order-1 text-neutral-600">
              © 2024 <a href="https://icmsaust.com.au/" target="_blank" rel="noopener noreferrer" className="text-dteal">ICMSA</a>. | <a href="https://icmsaust.com.au/privacy-policy/" target="_blank" rel="noopener noreferrer" className="text-dteal">Privacy Policy</a>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}
